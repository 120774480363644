<template>
    <div>
        <page-title :heading="this.$t('analytic.lang_nav_analytics_QTYwaregroups')" :icon="icon"
                    :subheading="this.$t('analytic.lang_nav_analytics_QTYwaregroups')"></page-title>
        <div class="app-main__inner">
            <article-groups-component/>
        </div>
    </div>
</template>

<script>
    import ArticleGroupsComponent from "../../../../components/analytics/warewanlytics/articlegroups/ArticleGroupsComponent";
    import PageTitle from "../../../../Layout/Components/PageTitle";
    export default {
        name: "index",
        components: {PageTitle, ArticleGroupsComponent},
        data: () => ({
            icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>